var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.thresholdDisplayPerWorkOrder")))]),_c('small',{staticClass:"mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("general.chooseThresholdDisplayPerWorkOrderTxt")))]),_c('div',{class:{
      'is-invalid': _vm.thresholdDisplayPerWorkOrder.$error,
    },staticStyle:{"display":"flex","align-items":"center"}},[_c('multiselect',{class:{
        'is-invalid': _vm.thresholdDisplayPerWorkOrder.$error,
      },staticStyle:{"max-width":"300px"},attrs:{"validator":_vm.thresholdDisplayPerWorkOrder,"placeholder":_vm.$t('general.chooseThresholdCalculation'),"options":_vm.thresholdCalculationTypes,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}},{key:"option",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}}]),model:{value:(_vm.thresholdDisplayPerWorkOrder.$model),callback:function ($$v) {_vm.$set(_vm.thresholdDisplayPerWorkOrder, "$model", $$v)},expression:"thresholdDisplayPerWorkOrder.$model"}}),(_vm.isDisplayCriticalRatio)?_c('i',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapseConfig",modifiers:{"collapseConfig":true}}],staticClass:"ml-2",class:{
        'fas fa-cog': !_vm.openWorkOrderDisplayCriticalRatioConfig,
        'fas fa-cog ': _vm.openWorkOrderDisplayCriticalRatioConfig,
      },staticStyle:{"position":"relative","top":"-2px","font-size":"1.8em","cursor":"pointer"},on:{"click":_vm.changeWorkOrderDisplayCriticalRatioConfig}}):_vm._e()],1),(_vm.thresholdDisplayPerWorkOrder.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.thresholdDisplayPerWorkOrder.required)?_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }