<script>
export default {
  model: {
    prop: "thresholdDisplayPerWorkOrder",
    event: "input",
  },
  props: ["thresholdDisplayPerWorkOrder"],
  components: {},
  data() {
    return {
      openWorkOrderDisplayCriticalRatioConfig: false,
    };
  },
  computed: {
    isDisplayCriticalRatio() {
      return this.thresholdDisplayPerWorkOrder.$model === "CriticalRatio";
    },
    thresholdCalculationTypes() {
      if (this.isDisplayCriticalRatio) {
        return [
          "CriticalRatio",
          "OperationDate",
          "DeliveryDate",
          "InstallationDate",
          "ProductionDate",
        ];
      } else {
        return [
          "OperationDate",
          "DeliveryDate",
          "InstallationDate",
          "ProductionDate",
        ];
      }
    },
  },
  watch: {},
  methods: {
    changeWorkOrderDisplayCriticalRatioConfig() {
      this.openWorkOrderDisplayCriticalRatioConfig =
        !this.openWorkOrderDisplayCriticalRatioConfig;
      this.$emit("showCriticalRatioConfig");
    },
  },
};
</script>
<template>
  <b-form-group>
    <label>{{ $t("general.thresholdDisplayPerWorkOrder") }}</label>
    <small class="mb-1" style="display: block">{{
      $t("general.chooseThresholdDisplayPerWorkOrderTxt")
    }}</small>
    <div
      :class="{
        'is-invalid': thresholdDisplayPerWorkOrder.$error,
      }"
      style="display: flex; align-items: center"
    >
      <multiselect
        :validator="thresholdDisplayPerWorkOrder"
        :class="{
          'is-invalid': thresholdDisplayPerWorkOrder.$error,
        }"
        style="max-width: 300px"
        :placeholder="$t('general.chooseThresholdCalculation')"
        v-model="thresholdDisplayPerWorkOrder.$model"
        :options="thresholdCalculationTypes"
        :show-labels="false"
      >
        <template slot="singleLabel" slot-scope="props">
          <span>{{ $t("general." + props.option) }}</span>
        </template>
        <template slot="option" slot-scope="props">
          <span>{{ $t("general." + props.option) }}</span>
        </template>
      </multiselect>
      <i
        v-if="isDisplayCriticalRatio"
        @click="changeWorkOrderDisplayCriticalRatioConfig"
        v-b-toggle.collapseConfig
        v-bind:class="{
          'fas fa-cog': !openWorkOrderDisplayCriticalRatioConfig,
          'fas fa-cog ': openWorkOrderDisplayCriticalRatioConfig,
        }"
        class="ml-2"
        style="position: relative; top: -2px; font-size: 1.8em; cursor: pointer"
      >
      </i>
    </div>

    <div v-if="thresholdDisplayPerWorkOrder.$error" class="invalid-feedback">
      <span v-if="!thresholdDisplayPerWorkOrder.required">{{
        $t("general.requiredValue")
      }}</span>
    </div>
  </b-form-group>
</template>