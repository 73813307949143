<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ProgressPrioritizationModal from "./components/progress-prioritization-modal";
import MaestroMenuLayout from "../components/maestro-menu-layout";
import CalendarApiService from "@/services/calendarService";
import PrioritizationService from "@/services/prioritization-service";
import DeleteModal from "@/components/delete-modal";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "maestro",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    MaestroMenuLayout,
    ProgressPrioritizationModal,
    DeleteModal,
  },
  data() {
    return {
      isLoading: false,
      progressPrioritizationList: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch("calendar/fetchProgress");
  },
  created() {
    this.loadData();
  },
  mounted() {},
  computed: {},
  methods: {
    loadData() {
      this.isLoading = true;
      CalendarApiService.getProgressSchedulingList().then((response) => {
        this.progressPrioritizationList = response.data.data;
        this.progressPrioritizationList?.sort(
          PrioritizationService.getSortPriorizationFct()
        );
        this.isLoading = false;
      });
    },
    getFirstCalculation(progressScheduling) {
      if (
        progressScheduling.priorityCalculations == null ||
        progressScheduling.priorityCalculations.length == 0
      ) {
        return null;
      } else {
        let copy = progressScheduling.priorityCalculations.slice();
        copy.sort((a, b) => (a.order > b.order ? 1 : -1));
        return copy[0].calculationType;
      }
    },
    openDeleteProgressPrioritizationModal(progressPrioritization) {
      this.$refs.deleteProgressPrioritizationModal
        .show(progressPrioritization, progressPrioritization.progress.name)
        .then(
          (config) => {
            CalendarApiService.deleteProgressSchedulingConfig(config.id).then(
              () => {
                var index = this.progressPrioritizationList
                  .map((pp) => {
                    return pp.id;
                  })
                  .indexOf(config.id);
                this.progressPrioritizationList.splice(index, 1);
              }
            );
          },
          () => {}
        );
    },
    openCreateProgressPrioritizationModal() {
      this.$refs.progressPrioritizationModal.showCreate().then(() => {
        this.loadData();
      });
    },
    openEditProgressPrioritizationModal(progressPrioritization) {
      this.$refs.progressPrioritizationModal
        .showEdit(progressPrioritization.id)
        .then(() => {
          this.loadData();
        });
    },
  },
};
</script>

<template>
  <Layout :contentPadding="false">
    <MaestroMenuLayout ref="maestroMenu">
      <div style="padding: 24px">
        <div style="display: flex">
          <i
            @click="$refs.maestroMenu.toggleMenu()"
            style="cursor: pointer; font-size: 1.5em; margin-right: 8px"
            class="fa fa-fw fa-bars"
          ></i>
          <PageHeader :title="$t('general.prioritiesCalculation')" />
        </div>
        <div class="card">
          <div class="card-body">
            <div zclass="row">
              <div czlass="col-12">
                <div style="display: flex" class="mb-3">
                  <p class="card-title-desc" style="margin-bottom: 0">
                    {{ $t("general.prioritiesCalculationConfig") }}
                  </p>
                  <b-button
                    style="margin-left: auto"
                    @click="openCreateProgressPrioritizationModal()"
                    variant="primary"
                    >{{ $t("general.add") }}</b-button
                  >
                </div>

                <div class="table-responsive" style="position: relative">
                  <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    color="#f1b44c"
                    :width="70"
                    :height="70"
                    loader="dots"
                    :is-full-page="false"
                  >
                  </loading>
                  <table class="table table-hover table-centered">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.progress") }}</th>
                        <th style="text-align: center">
                          {{ $t("general.calculation") }}
                        </th>
                        <th
                          style="width: 100px; text-align: center; width: 200px"
                        >
                          {{ $t("general.action") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(pp, index) in progressPrioritizationList"
                        :key="index"
                      >
                        <td>
                          <b-badge
                            :style="{ background: pp.progress.colorHexa }"
                            style="font-size: 1.1em"
                            >{{ pp.progress.name }}</b-badge
                          >
                        </td>
                        <td style="text-align: center">
                          {{
                            getFirstCalculation(pp) == null
                              ? ""
                              : $t("general." + getFirstCalculation(pp))
                          }}
                        </td>
                        <td style="text-align: center">
                          <b-dropdown
                            dropleft
                            size="lg"
                            class="card-drop"
                            variant="link"
                            toggle-class="text-decoration-none p-0"
                            no-caret
                          >
                            <template #button-content>
                              <i class="fas fa-ellipsis-h" />
                            </template>

                            <b-dropdown-item
                              @click="openEditProgressPrioritizationModal(pp)"
                            >
                              <i
                                class="fas fa-pencil-alt text-success mr-1"
                              ></i>
                              {{ $t("general.edit") }}
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click="openDeleteProgressPrioritizationModal(pp)"
                            >
                              <i class="fas fa-trash-alt text-danger mr-1"></i>
                              {{ $t("general.delete") }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MaestroMenuLayout>

    <ProgressPrioritizationModal ref="progressPrioritizationModal" />
    <DeleteModal
      ref="deleteProgressPrioritizationModal"
      :title="$t('general.deleteProgressSchedulingConfigTitle')"
      :message="$t('general.areYouSureDeleteProgressSchedulingConfig')"
    />
  </Layout>
</template>
