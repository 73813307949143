<script>
import ProgressService from "@/services/progress-service";
export default {
  model: {
    prop: "flashingDisplayConfigs",
    event: "input",
  },
  props: ["flashingDisplayConfigs"],
  components: {},
  data() {
    return {
      currentFlashingDisplayConfig: {
        progress: undefined,
        minPercentCompleted: 0
      }
    };
  },
  methods: {
    addFlashingProgressConfig() {
      this.flashingDisplayConfigs.push(this.currentFlashingDisplayConfig);
      this.currentFlashingDisplayConfig = {
        progress: undefined,
        minPercentCompleted: 0
      };
    },
    deleteFlashingDisplayConfig(index) {      
      this.flashingDisplayConfigs.splice(index, 1);
    }
  },
  computed:{
    unusedProgresses() {
      var unusedProgresses = this.$store.state.calendar.progress.filter(x => this.flashingDisplayConfigs.find(conf => conf.progress.id === x.id) === undefined);
      unusedProgresses.sort(ProgressService.getSortFct());
      return unusedProgresses;
    }
  }
};
</script>
<template>
  <div>
    <small class="mb-1 mt-2" style="display: block">{{
      $t("general.flashingDisplayInfo")
    }}</small>
    <div class="d-flex flex-row">
      <div class="mb-1">
        <label>{{ $t("general.progress") }}</label>
        <div class="d-flex align-items-center">
          <multiselect class="mr-2" style="width: 100%; width: 250px; max-width: 300px"
            :placeholder="$t('general.chooseProgress')" v-model="currentFlashingDisplayConfig.progress"
            :options="unusedProgresses" :show-labels="false" label="name" :allow-empty="false"
            track-by="id"></multiselect>
        </div>
      </div>
      <div class="mb-1">
        <label>{{ $t("general.minWorkOrder%Completion") }}</label>
        <div style="display: flex; align-items: center">
          <b-form-input class="mr-2" type="number" min="0" max="100" @blur.native="
            currentFlashingDisplayConfig.minPercentCompleted =
            !currentFlashingDisplayConfig.minPercentCompleted
              ? 0
              : currentFlashingDisplayConfig.minPercentCompleted
            " v-model="currentFlashingDisplayConfig.minPercentCompleted" placeholder="0"
            style="max-width: 90px; height: 38px">
          </b-form-input>
        </div>
      </div>
      <div class="d-flex align-items-center mb-1 align-self-end">
        <b-button @click="addFlashingProgressConfig" class="ml-1" variant="success">{{ $t("general.add") }}</b-button>
      </div>
    </div>
    <div class="d-flex light-draggable-card" v-for="(fc, index) in flashingDisplayConfigs" :key="index">
      <div  style="width: 100%; width: 250px; max-width: 300px">{{ fc.progress.name }}</div>
      <div class="mr-2 align-items-center" style="flex:1">{{ fc.minPercentCompleted }} %</div>
        <div style="text-align: center; width: 50px">
          <i
            @click="deleteFlashingDisplayConfig(index)"
            style="font-size: 1.3em; cursor: pointer"
            class="fas fa-trash-alt text-danger"
          ></i>
        </div>
    </div>
  </div>
</template>