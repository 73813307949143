<script>
import { required } from "vuelidate/lib/validators";
import CalendarApiService from "@/services/calendarService";
import ProgressSelector from "./components/progress-selector";
import DisplayAttributeSelector from "./components/display-attribute-selector";
import FlashingDisplayConfig from "./components/flashing-display-config";
import ThresholdConfig from "./components/thresholds-config";
import CriticalRatioThresholdConfig from "./components/critical-ratio-threshold-config";
import PrioritizationCalculationList from "../../../components/prioritization-calculation-list";
import PrioritizationCalculationConfig from "../../../components/prioritization-calculation-config";

export default {
  components: {
    ProgressSelector,
    DisplayAttributeSelector,
    FlashingDisplayConfig,
    ThresholdConfig,
    CriticalRatioThresholdConfig,
    PrioritizationCalculationConfig,
    PrioritizationCalculationList,
  },
  data() {
    return {
      form: undefined,
      showThresholdCriticalRatioConfig: false,
      selectedPrioritizationCalculation: null,
    };
  },
  methods: {
    submit() {
      this.$v.form.$touch();
    },
    reset() {
      this.form = undefined;
      this.selectedPrioritizationCalculation = undefined;
      this.$v.$reset();
    },
    submitCreate() {
      return this.save((model) =>
        CalendarApiService.createProgressSchedulingConfig(model)
      );
    },
    submitEdit() {
      return this.save((model) =>
        CalendarApiService.updateProgressSchedulingConfig(model)
      );
    },
    save(fct) {
      return new Promise((resolve, reject) => {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          var modelCopy = JSON.parse(JSON.stringify(this.form));

          if (modelCopy.flashingDisplayConfigs) {
            for(var flashingDisplayConfig of modelCopy.flashingDisplayConfigs) {
              flashingDisplayConfig.minPercentCompleted = flashingDisplayConfig.minPercentCompleted / 100
            }
          }
          fct(modelCopy).then(
            () => {
              resolve();
            },
            (error) => reject(error)
          );
        } else {
          reject();
        }
      });
    },
    setNewProgressPrioritization() {
      this.$v.$reset();
      this.showThresholdCriticalRatioConfig = false;
      this.form = {
        progress: undefined,
        thresholdDisplayPerWorkOrder: null,
        attributeDisplayPerWorkOrder: null,
        useFlashingDisplay: false,
        flashingDisplayConfigs: [],
        workOrderDisplayCriticalRatioConfig: {
          attribute: "Products",
          nva: 0,
          dateType: "Delivery",
        },
        priorityCalculations: [],
      };
    },
    loadProgressPrioritization(progressPrioritizationId) {
      this.$v.$reset();
      return CalendarApiService.getProgressSchedulingConfig(
        progressPrioritizationId
      ).then((response) => {
        this.form = response.data.data;

        //Set percent display value
        if (this.form.flashingDisplayConfigs) {
          
          for(var flashingDisplayConfig of this.form.flashingDisplayConfigs) {
              flashingDisplayConfig.minPercentCompleted = flashingDisplayConfig.minPercentCompleted * 100
            }
        }

        //order calculations for draggable
        if (
          this.form.priorityCalculations != null ||
          typeof this.form.priorityCalculations != "undefined"
        ) {
          this.form.priorityCalculations.sort((a, b) =>
            a.order > b.order ? 1 : b.order > a.order ? -1 : 0
          );
        }

        //order progress dependencies
        if (
          this.form.priorityCalculations != null ||
          typeof this.form.priorityCalculations != "undefined"
        ) {
          let progressDependenciesConfig =
            this.form.priorityCalculations.filter((pc) => {
              return pc.calculationType == "ProgressDependencies";
            });
          if (progressDependenciesConfig.length > 0) {
            progressDependenciesConfig = progressDependenciesConfig[0];
            if (
              Array.isArray(
                progressDependenciesConfig.progressDependenciesCalculationConfig
                  ?.progressDependencies
              )
            ) {
              progressDependenciesConfig.progressDependenciesCalculationConfig?.progressDependencies.sort(
                (a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
              );
            }
          }
        }

        //order attributes dependencies
        if (
          this.form.priorityCalculations != null ||
          typeof this.form.priorityCalculations != "undefined"
        ) {
          let attributescalculationConfig =
            this.form.priorityCalculations.filter((pc) => {
              return pc.calculationType == "Attributes";
            });
          if (attributescalculationConfig.length > 0) {
            attributescalculationConfig = attributescalculationConfig[0];
            if (
              Array.isArray(
                attributescalculationConfig.attributesCalculationConfig
                  ?.attributesDependencies
              )
            ) {
              attributescalculationConfig.attributesCalculationConfig?.attributesDependencies.sort(
                (a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
              );
            }
          }
        }

        //order keywords dependencies
        if (
          this.form.priorityCalculations != null ||
          typeof this.form.priorityCalculations != "undefined"
        ) {
          let keywordscalculationConfig = this.form.priorityCalculations.filter(
            (pc) => {
              return pc.calculationType == "Keywords";
            }
          );
          if (keywordscalculationConfig.length > 0) {
            keywordscalculationConfig = keywordscalculationConfig[0];
            if (
              Array.isArray(
                keywordscalculationConfig.keywordsCalculationConfig
                  ?.keywordsDependencies
              )
            ) {
              keywordscalculationConfig.keywordsCalculationConfig?.keywordsDependencies.sort(
                (a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
              );
            } else {
              keywordscalculationConfig.keywordsCalculationConfig.keywordsDependencies = [];
            }
          }
        }
      });
    },
    toggleThresholdConfigCriticalRatio() {
      this.showThresholdCriticalRatioConfig =
        !this.showThresholdCriticalRatioConfig;
      if (!this.form.workOrderDisplayCriticalRatioConfig) {
        this.form.workOrderDisplayCriticalRatioConfig = {};
      }
    },
    newPriorityCalculation(priorityCalculation) {
      if (priorityCalculation.calculationType == "CriticalRatio") {
        if (
          this.form.thresholdDisplayPerWorkOrder != null &&
          this.form.thresholdDisplayPerWorkOrder == "CriticalRatio"
        ) {
          priorityCalculation.criticalRatioCalculationConfig = {
            attribute: this.form.workOrderDisplayCriticalRatioConfig.attribute,
            nva: this.form.workOrderDisplayCriticalRatioConfig.nva,
            dateType: this.form.workOrderDisplayCriticalRatioConfig.dateType,
          };
        } else {
          priorityCalculation.criticalRatioCalculationConfig = {
            attribute: "Products",
            nva: 0,
            dateType: "Delivery",
          };
        }
      } else if (
        priorityCalculation.calculationType == "ProgressDependencies"
      ) {
        priorityCalculation.progressDependenciesCalculationConfig = {
          progressDependencies: [],
        };
      } else if (priorityCalculation.calculationType == "Attributes") {
        priorityCalculation.attributesCalculationConfig = {
          attributesDependencies: [],
        };
      } else if (priorityCalculation.calculationType == "Keywords") {
        priorityCalculation.keywordsCalculationConfig = {
          keywordsDependencies: [],
        };
      }
      this.selectedPrioritizationCalculation = priorityCalculation;
    },
    selectPrioritizationCalculation(prioritizationCalculation) {
      this.selectedPrioritizationCalculation = prioritizationCalculation;
    },
  },
  mounted() {},
  computed: {},
  validations: {
    form: {
      progress: { required },
      attributeDisplayPerWorkOrder: { required },
      thresholdDisplayPerWorkOrder: { required },
      priorityCalculations: {
        $each: {
          calculationType: { required },
          attributesCalculationConfig: {
            attributesDependencies: {
              $each: {
                attribute: { required },
                orderBy: { required },
              },
            },
          },
        },
      },
    },
  },
};
</script>
<template>
  <b-form @submit.prevent="submit" v-if="form">
    <div class="row">
      <div class="col-sm-6 col-12">
        <ProgressSelector
          v-model="$v.form.progress"
          :editMode="form.id != undefined"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <DisplayAttributeSelector
          v-model="$v.form.attributeDisplayPerWorkOrder"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group>
          <label>{{ $t("general.flashingDisplay") }}</label>
          <b-form-checkbox
            v-model="form.useFlashingDisplay"
            class="custom-checkbox mb-1"
            >{{ $t("general.useFlashingDisplay") }}</b-form-checkbox
          >
          <FlashingDisplayConfig
            v-if="form.useFlashingDisplay"
            v-model="form.flashingDisplayConfigs"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ThresholdConfig
          v-model="$v.form.thresholdDisplayPerWorkOrder"
          @showCriticalRatioConfig="toggleThresholdConfigCriticalRatio"
        />
      </div>
    </div>
    <div
      v-if="form.workOrderDisplayCriticalRatioConfig"
      v-show="
        showThresholdCriticalRatioConfig &&
        form.thresholdDisplayPerWorkOrder == 'CriticalRatio'
      "
      class="row"
    >
      <div class="col-12">
        <CriticalRatioThresholdConfig
          v-model="form.workOrderDisplayCriticalRatioConfig"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <PrioritizationCalculationList
          v-model="$v.form.priorityCalculations"
          @added="newPriorityCalculation"
          @select="selectPrioritizationCalculation"
        />
      </div>
    </div>
    <hr />
    <div class="row" v-if="selectedPrioritizationCalculation">
      <div class="col-12">
        <PrioritizationCalculationConfig
          v-model="selectedPrioritizationCalculation"
        />
      </div>
    </div>
  </b-form>
</template>