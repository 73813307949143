
<script>
import ProgressPrioritizationForm from "./progress-prioritization-form";

export default {
  components: {
    ProgressPrioritizationForm,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      confirmProgressPrioritization: null,
      mode: undefined,
    };
  },
  beforeCreate() {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    showCreate() {
      return new Promise((resolve) => {
        this.confirmProgressPrioritization = () => {
          this.isLoading = true;
          this.$refs.progressPrioritizationForm
            .submitCreate()
            .then(
              () => {
                resolve();
              },
              () => {}
            )
            .finally(() => {
              this.isLoading = false;
            });
        };
        this.mode = "create";
        this.$refs.progressPrioritizationForm.setNewProgressPrioritization();
        this.showModal = true;
      }).finally(() => {
        this.closeModal();
      });
    },
    showEdit(progressPrioritizationId) {
      return new Promise((resolve) => {
        this.mode = "edit";
        this.isLoading = true;
        this.showModal = true;
        this.$refs.progressPrioritizationForm
          .loadProgressPrioritization(progressPrioritizationId)
          .finally(() => {
            this.isLoading = false;
          });
        this.confirmProgressPrioritization = () => {
          this.isLoading = true;
          this.$refs.progressPrioritizationForm
            .submitEdit()
            .then(
              () => {
                resolve();
              },
              () => {}
            )
            .finally(() => {
              this.isLoading = false;
            });
        };
      }).finally(() => {
        this.closeModal();
      });
    },
    closeModal() {
      this.showModal = false;
      this.isLoading = false;
      this.$refs.progressPrioritizationForm.reset();
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="
      mode === 'create'
        ? $t('general.createPriorityCalculation')
        : $t('general.editPriorityCalculation')
    "
    title-class="text-black font-18"
    body-class="p-4"
    size="lg"
    no-close-on-backdrop
    static
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    >
    </loading>
    <ProgressPrioritizationForm ref="progressPrioritizationForm" />

    <template #modal-footer class="text-right pt-3">
      <b-button variant="light" @click="closeModal">{{
        $t("general.close")
      }}</b-button>
      <b-button
        @click="confirmProgressPrioritization"
        :disabled="isLoading"
        v-if="mode === 'edit'"
        class="ml-1"
        variant="success"
        >{{ $t("general.save") }}</b-button
      >
      <b-button
        @click="confirmProgressPrioritization"
        :disabled="isLoading"
        v-else
        class="ml-1"
        variant="success"
        >{{ $t("general.create") }}</b-button
      >
    </template>
  </b-modal>
</template>