<script>
export default {
  model: {
    prop: "attribute",
    event: "input",
  },
  props: ["attribute"],
  components: {},
  computed: {},
};
</script>
<template>
  <b-form-group>
    <label>{{ $t("general.attributeDisplayPerWorkOrder") }}</label>
    <small class="mb-1" style="display: block">{{
      $t("general.chooseAttributeDisplayPerWorkOrderTxt")
    }}</small>
    <multiselect
      :validator="attribute"
      :class="{
        'is-invalid': attribute.$error,
      }"
      style="max-width: 300px"
      :placeholder="$t('general.chooseAttributeDisplay')"
      v-model="attribute.$model"
      :options="measureTypes"
      :show-labels="false"
    >
      <template slot="singleLabel" slot-scope="props">
        <span>{{ $t("general." + props.option) }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span>{{ $t("general." + props.option) }}</span>
      </template>
    </multiselect>
    <div v-if="attribute.$error" class="invalid-feedback">
      <span v-if="!attribute.required">{{ $t("general.requiredValue") }}</span>
    </div>
  </b-form-group>
</template>