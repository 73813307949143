<script>
import CalendarApiService from "@/services/calendarService";
import ProgressService from "@/services/progress-service";
export default {
  model: {
    prop: "progress",
    event: "input",
  },
  props: ["progress", "editMode"],
  components: {},
  data() {
    return {
      unusedProgress: [],
    };
  },
  created() {
    CalendarApiService.getUnusedProgressForSchedulingConfig().then(
      (response) => {
        this.unusedProgress = response.data.data;
        this.unusedProgress.sort(ProgressService.getSortFct());
      }
    );
  },
};
</script>
<template>
  <b-form-group>
    <label>{{ $t("general.progress") }}</label>
    <div v-if="editMode && progress.$model != null">
      <b-badge
        :style="{
          background: progress.$model.colorHexa,
        }"
        style="font-size: 1.3em"
        >{{ progress.$model.name }}</b-badge
      >
    </div>
    <multiselect
      v-if="!editMode"
      :validator="progress"
      :class="{
        'is-invalid': progress.$error,
      }"
      style="max-width: 300px"
      :placeholder="$t('general.chooseProgress')"
      v-model="progress.$model"
      :options="unusedProgress"
      :show-labels="false"
      label="name"
      track-by="id"
    ></multiselect>

    <div v-if="progress.$error" class="invalid-feedback">
      <span v-if="!progress.required">{{ $t("general.requiredValue") }}</span>
    </div>
  </b-form-group>
</template>